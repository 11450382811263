import "./NavBar.css";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import i18next from "i18next";
import en from "../../Assets/en.webp";
import fr from "../../Assets/fr.webp";
import Ylio from "../../Assets/YLIO.svg";

const NavBar = () => {
  const { t } = useTranslation();
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const currentLanguage = localStorage.getItem("i18nextLng");
  const languages = [
    {
      code: "fr",
      name: "Français",
      country_code: "fr",
      icon: fr,
    },
    {
      code: "en",
      name: "English",
      country_code: "gb",
      icon: en,
    },
  ];

  useEffect(() => {
    sidebarIsOpen
      ? (document.body.className = "overflow_hidden")
      : (document.body.className = "");
  }, [sidebarIsOpen]);

  return (
    <div className="navbar w-100  pl-30 pr-30 flex align_items_center">
      <div className="w-100">
        <Link to="/" className="logo no-border no-background no-padding">
          <img alt="logo" src={Ylio} />
        </Link>
      </div>
      <nav className="w-100">
        <ul className="flex  align_items_center ">
          <li>
            <Link to="/services">{t("navbar_services")}</Link>
          </li>
          <li>
            <Link to="/portofolio">{t("navbar_portofolio")}</Link>
          </li>
        </ul>
      </nav>
      <ul className="language">
        {languages.map((language, i) => {
          return (
            <li key={i}>
              <button
                className="no-border no-background pointer"
                aria-disabled={currentLanguage === language.code}
                onClick={() => {
                  return (
                    currentLanguage !== language.code &&
                    i18next.changeLanguage(language.code)
                  );
                }}
              >
                <img alt="français" src={language.icon} />
              </button>
            </li>
          );
        })}
      </ul>
      <button
        id="burger"
        onClick={() => setSidebarIsOpen((isOpen) => !isOpen)}
        className="burger vertical no-border no-background no-padding justify"
      >
        <span className="burger_line"></span>
        <span className="burger_line"></span>
        <span className="burger_line"></span>
      </button>
      <div className="bandeau" isopen={sidebarIsOpen.toString()}>
        <div
          onClick={() => setSidebarIsOpen((isOpen) => !isOpen)}
          className="overlay fixed"
        ></div>
        <div className="sidebar">
          <ul className="flex vertical">
            <li>
              <img className="mb-0" alt="logo" src={Ylio} />
            </li>
            <li>
              <Link
                to="/services"
                onClick={() => setSidebarIsOpen((isOpen) => !isOpen)}
              >
                {t("navbar_services")}
              </Link>
            </li>
            <li>
              <Link
                to="/portofolio"
                onClick={() => setSidebarIsOpen((isOpen) => !isOpen)}
              >
                {t("navbar_portofolio")}
              </Link>
            </li>
            <li className="flex">
              {languages.map((language, i) => {
                return (
                  <button
                    key={i}
                    className="lang no-border no-background pointer"
                    aria-disabled={currentLanguage === language.code}
                    onClick={() => {
                      return (
                        currentLanguage !== language.code &&
                        i18next.changeLanguage(language.code)
                      );
                    }}
                  >
                    <img alt="français" src={language.icon} />
                  </button>
                );
              })}
            </li>
          </ul>

          <button
            id="close_nav"
            onClick={() => setSidebarIsOpen((isOpen) => !isOpen)}
            className="close no-background"
          >
            X
          </button>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
