const Cookies = () => {
  return (
    <div className="container mentions">
      <h1 className="section_title_fs fw_600 text_align_center">
        Politique de gestion des cookies
      </h1>
      <div className="section">
        <div className="section_title_fs">Qu'est ce qu'un cookie ?</div>
        <p className="lineheight mw-800 ">
          Un cookie est un petit fichier texte déposé sur le disque dur de votre
          terminal lors de votre visite sur un site internet. Il enregistre
          certaines informations sur votre navigation ou sur votre comportement
          en ligne.
        </p>

        <div className="section_title_fs">Utilisons-nous des cookies ?</div>
        <p className="lineheight mw-800 ">
          Pour garder toute transparence avec nos utilisateurs, nous ne faisons
          absolument pas usage de cookie, seules les données issues des formulaires du site
          nous permettent de recueillir des données.
        </p>
      </div>
    </div>
  );
};

export default Cookies;
