import { useState } from "react";
import "./Portofolio.css";
import rayzon from "../../Assets/Rayzon.svg";
import CDR from "../../Assets/CDR_logo.webp";
import Rayzon from "../../Components/portofolio_bis/Rayzon";
import CleanDataRights from "../../Components/portofolio_bis/CleanDataRights";
import black_arrow from "../../Assets/black_arrow_up.webp";

const Portofolio = () => {
  const [slide, setSlide] = useState(0);
  const [init, setInit] = useState(true);

  return (
    <div className="portofolio">
      <section className="hero slider relative">
        <div className="slide_wrapper relative">
          <div
            data-active={slide === 0 ? "true" : "false"}
            className="slide absolute rayzon"
          >
            <div className="flex justify ">
              <img alt="Rayzon" src={rayzon} />
            </div>
            <div className="rayzon slide_background absolute"></div>
            <div className="navbar_filter absolute"></div>
          </div>
          <div
            data-load={init ? "false" : "true"}
            data-active={slide === 1 ? "true" : "false"}
            className="CDR slide absolute"
          >
            <div className="flex justify ">
              <img alt="Clean Data Rights" src={CDR} />
            </div>
            <div className="CDR slide_background absolute"></div>
            <div className="navbar_filter absolute"></div>
          </div>
        </div>
        <div className="slider_nav flex absolute">
          <button
            data-active={slide === 0 ? "true" : "false"}
            className="rayzon_slide"
            onClick={() => {
              setSlide(0);
              setInit(false);
            }}
          ></button>
          <button
            data-active={slide === 1 ? "true" : "false"}
            className="CDR_slide"
            onClick={() => {
              setSlide(1);
              setInit(false);
            }}
          ></button>
        </div>
      </section>
      {slide === 0 ? <Rayzon /> : <CleanDataRights />}
      
      <button
        onClick={() =>
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth", // Optional if you want to skip the scrolling animation
          })
        }
        className="top_page_button no-background no-border block"
      >
        <img alt="back" src={black_arrow} />
      </button>
    </div>
  );
};

export default Portofolio;
