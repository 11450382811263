import React from "react";
import { Routes, Route } from "react-router-dom";
import MentionsLegales from "./Pages/mentionsLegales/MentionsLegales";
import PolitiqueConfid from "./Pages/politiqueConfid/PolitiqueConfid";
import Cookies from "./Pages/cookies/Cookies";
import NavBar from "./Components/navBar/NavBar";
import Footpage from "./Components/footpage/Footpage";
import LandingPage from "./Pages/landingPage/landingPage";
import Services from "./Pages/services/Services";
import Portofolio from "./Pages/portofolio/Portofolio";

function App() {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/services" element={<Services />} />
        <Route path="/portofolio" element={<Portofolio />} />
        <Route path="/mentionslegales" element={<MentionsLegales />} />
        <Route path="/confidentialite" element={<PolitiqueConfid />} />
        <Route path="/cookies" element={<Cookies />} />
      </Routes>
      <Footpage />
    </>
  );
}

export default App;

/*  
const Admin = lazy(() => import("./Pages/Administration/admin"));
const Login = lazy(() => import("./Pages/Login/login"));

<Route exact path="/loginadminrayzon" component={Login}/>
                <Route exact path="/administration" component={Admin}/>*/
