import "./MentionsLegales.css";
const MentionsLegales = () => {
  return (
    <div className="container mentions">
        <h1 className="section_title_fs fw_600 text_align_center">Mentions légales</h1>
      <div className="section">
        <div className="section_title_fs">Éditeur</div>
        <p className="lineheight mw-800 ">
          Le présent site web, accessible à l'adresse www.ylio.net
          est édité par Ylio - adresse électronique
          :contact@ylio.net.
          <br /> <br />
          Ylio est un agence web spécialisé dans la création d'identité numérique, de design et developpement web.
          Le directeur de la publication du Site Web est Monsieur Ramy Al Aloocy.
        </p>
        <div className="section_title_fs">Contenu du Site</div>
        <p className="lineheight mw-800 ">
          Le contenu du site, la structure générale, les textes, les images,
          animées ou non, et les sons dont le Site est composé sont la propriété
          exclusive de Ylio. Toute représentation totale ou
          partielle de ce Site et de son Contenu, par quelques procédés que ce
          soient, sans l'autorisation préalable expresse de Ylio
          est interdite et constituerait une contrefaçon sanctionnée par les
          articles L.335-2 et suivants du Code de la Propriété intellectuelle.
          Le site accessible à l'adresse www.haas-avocats.com<br/>
        </p>
        <div className="section_title_fs">
          Licence d'utilisation du Contenu du Site
        </div>
        <p className="lineheight mw-800 ">
          Du seul fait de sa connexion au site, l'utilisateur reconnaît accepter
          de Ylio, une licence d'usage du Contenu du Site
          strictement limitée aux conditions impératives suivantes :<br />
          <br /> La présente licence est accordée à titre non exclusif et n'est
          pas transmissible.
          <br />
          <br />
          Le droit d'usage conféré à l'utilisateur/internaute est personnel et
          privé : c'est-à-dire que toute reproduction du contenu du site sur un
          quelconque support pour un usage collectif ou professionnel, même en
          interne dans l'entreprise, est prohibée. Il en est de même pour toute
          communication de ce contenu par voie électronique, même diffusé en
          intranet ou en extranet d'entreprise.
          <br />
          <br /> Le droit d'usage comprend seulement l'autorisation de consulter
          le site et son contenu.
          <br />
          <br /> Cet usage comprend seulement l'autorisation de reproduire pour
          stockage aux fins de représentation sur écran monoposte et de
          reproduction en un exemplaire, pour copie de sauvegarde et tirage
          papier. Tout autre usage est soumis à l'autorisation expresse
          préalable de Ylio. La violation de ces dispositions
          soumet le contrevenant et toutes personnes responsables aux peines
          pénales et civiles prévues par la loi française.
        </p>
        <div className="section_title_fs">
          Loi informatique, fichiers et libertés{" "}
        </div>
        <p className="lineheight mw-800 ">
          Ylio est le responsable du traitement des données
          collectées sur le Site de Ylio.
          <br />
          <br /> L'utilisateur est notamment informé que conformément à
          l'article 32 de la loi Informatique et libertés du 6 janvier 1978
          modifiée, les informations qu'il communique par les formulaires
          présents sur le Site de Ylio sont nécessaires pour
          répondre à sa demande et sont destinées aux services en charge de
          répondre à sa demande à des fins de suivi de cette demande.
          <br />
          <br />
          Conformément aux dispositions des articles 39 et 40 de la loi «
          Informatique et Libertés » du 6 janvier 1978 modifiée en 2004,
          l'utilisateur bénéficie d'un droit d'accès, de rectification, de mise
          à jour et d'effacement des informations qui le concernent, qu'il peut
          exercer en s'adressant à dpo@ylio.net. Conformément aux
          dispositions de l'article 38 de la loi « Informatique et Libertés » du
          6 janvier 1978 modifiée en 2004, l'utilisateur peut également
          s'opposer, pour des motifs légitimes à ce que ses données fassent
          l'objet d'un traitement et sans motif et sans frais, à ce que ses
          données soient utilisées à des fins de prospection commerciale.
          <br />
          <br />
          L'utilisateur est informé que lors de ses visites sur le Site
          www.ylio.net, un cookie peut s'installer automatiquement
          sur son logiciel de navigation.
          <br />
          <br /> Le cookie est un bloc de données qui ne permet pas d'identifier
          les utilisateurs mais sert à enregistrer des informations relatives à
          la navigation de celui-ci sur le site. Le paramétrage du logiciel de
          navigation permet d'informer de la présence de cookie et
          éventuellement, de la refuser de la manière décrite à l'adresse
          suivante www.cnil.fr.
          <br />
          <br /> L'utilisateur dispose d'un droit d'accès, de retrait et de
          modification des données à caractère personnel communiquées par le
          biais des cookies dans les conditions indiquées ci-dessus.
          <br />
          <br />
          L'utilisateur du Site de Ylio est tenu de respecter les
          dispositions de la loi Informatique et libertés du 6 janvier 1978
          modifiée dont la violation est passible de sanctions pénales. Il doit
          notamment s'abstenir, s'agissant des informations nominatives
          auxquelles il accède, de toute collecte, de toute utilisation
          détournée, et d'une manière générale, de tout acte susceptible de
          porter atteinte à la vie privée ou à la réputation des personnes.
        </p>
        <div className="section_title_fs">Marques</div>
        <p className="lineheight mw-800 ">
          Les marques de Ylio pouvant figurer sur le Site sont des
          marques déposées. Toute reproduction totale ou partielle de ces
          marques sans autorisation expresse de Ylio est donc
          prohibée.
        </p>
        <div className="section_title_fs">Liens Hypertextes</div>
        <p className="lineheight mw-800 ">
          Les liens hypertextes mis en place dans le cadre du présent site en
          direction d'autres ressources présentes sur le réseau Internet, ne
          sauraient engager la responsabilité de Ylio. Les
          utilisateurs/internautes du site ne peuvent mettre en place un lien
          hypertexte en direction de ce site sans l'autorisation expresse et
          préalable de Ylio. Autorisation de reproduction de
          contenus et de mise en place d'hyperliens Pour toute information et
          demande de reproduction d'un contenu paru sur le site
          www.ylio.net (textes, graphiques, illustrations) quel qu'en
          soit le support, comme pour toute autorisation de mise en place d'un
          hyperlien, l'utilisateur est invité à adresser sa demande e-mail à
          contact@ylio.net
        </p>
        <div className="section_title_fs">Respect de la vie privée</div>
        <p className="lineheight mw-800 ">
          Ylio respecte votre vie privée et s'engage à la protéger.
          La présente déclaration est destinée à vous informer de notre
          politique et de nos pratiques en matière de respect de la vie privée,
          ainsi que des choix que vous pouvez opérer sur la manière dont vos
          coordonnées sont recueillies en ligne et comment elles sont utilisées.
          Le site web et notre activité professionnelle sont régis par le Droit
          français. En cas de litige résultant de votre utilisation de nos sites
          web, seuls les Tribunaux français sont compétents.
        </p>
      </div>
    </div>
  );
};

export default MentionsLegales;
