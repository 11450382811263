import "./landingPage.css";
import { Suspense, lazy } from "react";
import { useTranslation } from 'react-i18next'
//import i18next from 'i18next'

import Card from "../../Components/card/card";
import dev from "../../Assets/dev.webp";
import branding from "../../Assets/branding.webp";
import socialMedia from "../../Assets/socialmedia.webp";
//import useLanguage from "../../hooks/useLanguage";
const Form = lazy(() => import("../../Components/Form/form"));

//const TypingText = lazy(() => import("./TypingText/typingText"));


function LandingPage() {
  //const langue = useLanguage();
  const { t } = useTranslation()


  return (
    <div className="homepage">
      <section className="section section1 ">
        <header className="container">
          <h1 className="intro_title blue">
            {t('homepage_herotitle')}
            <strong className="gold"> {t('homepage_herotitle_strong')}</strong>
          </h1>
          <p className="intro_text">
          {t('homepage_herotext')}
          </p>
        </header>
      </section>
      <main className="section services flex justify align_items_center">
        <div className="card_wrapper">
          <Card
            img={branding}
            title={t('homepage_services_branding_title')}
            desc={t('homepage_services_branding_text')}
            btn={t('homepage_services_branding_button')}
          />
          <Card
            img={dev}
            title={t('homepage_services_design_title')}
            desc={t('homepage_services_design_text')}
            btn={t('homepage_services_design_button')}
          />
          <Card
            img={socialMedia}
            title={t('homepage_services_content_title')}
            desc={t('homepage_services_content_text')}
            btn={t('homepage_services_content_button')}
          />
        </div>
      </main>
      <section className="section aboutus ">
        <div className="container">
          <header>
            <h2 className="blue">
            {t('homepage_aboutus_title')}
            </h2>
          </header>
          <p>
          {t('homepage_aboutus_text')}
          </p>
        </div>
      </section>
      <section className="section2">
        <div className="container">
          <div className="flex-ls justify align_items_center">
            <div className="form_desc row">
              <h2 className="form_title">{t('homepage_form_title')}</h2>
              <p className="form_text">{t('homepage_form_text')}</p>
            </div>
            <Suspense fallback={<p></p>}>
              <Form />
            </Suspense>
          </div>
        </div>
      </section>
    </div>
  );
}

export default LandingPage;
