import Copyright from "../copyright/copyright";
import "./Footpage.css";
import { useTranslation } from 'react-i18next'
import { Link } from "react-router-dom";
import Ylio from "../../Assets/YLIO.svg";
import { useLocation } from "react-router-dom";

const Footpage = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation()
  return (
    <footer data_path={pathname} className="footpage">
      <div  className="footpage_menu ml-20 mb-30 pt-30">
        <ul className="socialMedia">
          <li>
            <a
              className="footpage_link"
              href={"https://www.facebook.com/profile.php?id=100076459530472"}
              target="_blank"
              rel="noreferrer"
            >
              <p className="yt">Facebook</p>
            </a>
          </li>
          <li>
            <a
              className="footpage_link"
              href={"https://instagram.com/ylio.boostyourbrand"}
              target="_blank"
              rel="noreferrer"
            >
              <p className="insta">Instagram</p>
            </a>
          </li>
          <li>
            <a
              className="footpage_link"
              href={"https://www.linkedin.com/company/ylio"}
              target="_blank"
              rel="noreferrer"
            >
              <p className="lkd">Linkedin</p>
            </a>
          </li>
        </ul>
        <div className="internlink">
          <Link to="/services" className="footpage_link">
          {t('footpage_services')}
          </Link>
          <Link to="/portofolio" className="footpage_link">
          {t('footpage_portofolio')}
          </Link>
        </div>
        <div className="internlink">
          <Link to="mentionslegales" className="footpage_link">
          {t('footpage_mentions')}
          </Link>
          <Link to="cookies" className="footpage_link">
          {t('footpage_cookies')}
          </Link>
          <Link to="confidentialite" className="footpage_link">
          {t('footpage_privacy')}
          </Link>
        </div>
      </div>
      <Link to="/" className="footpage_logo no-border no-background no-padding">
        <img alt="logo" src={Ylio} />
      </Link>
      <Copyright />
    </footer>
  );
};

export default Footpage;
