import "./Services.css";
import { useTranslation } from 'react-i18next'

const Services = () => {
  const { t } = useTranslation()
  return (
    <>
      <div className="service_page">
        <section className="section section_introservices">
          <div className="container">
            <h1 className="intro_title blue">
              {t('services_hero_title')}
              <strong className="gold"> {t('services_hero_title_strong')}</strong>
            </h1>
            <p className="intro_text">
            {t('services_hero_text')}
            </p>
          </div>
        </section>
        <section className="services relative pb-100">
          <div className="service branding flex justify align_items_center">
            <div className="container flex align_items_center">
              <div>
                <header>
                  <h2 className="section_title_fs mw-600 blue">
                  {t('services_branding_title')}
                  </h2>
                </header>
                <p className="mw-600 mt-40 text_fs">
                {t('services_branding_text')}
                </p>
              </div>
            </div>
          </div>
          <div className="service dev flex justify align_items_center">
            <div className="container flex align_items_center">
              <div>
                <header>
                  <h2 className="section_title_fs mw-600 blue">
                  {t('services_dev_title')}
                  </h2>
                </header>
                <p className="mw-600 mt-40 text_fs">
                {t('services_dev_text')}
                </p>
              </div>
            </div>
          </div>
          <div className="service socialmedia flex justify align_items_center">
            <div className="container flex align_items_center">
              <div>
                <header>
                  <h2 className="section_title_fs mw-600 blue">
                  {t('services_content_title')}
                  </h2>
                </header>
                <p className="mw-600 mt-40 text_fs">
                {t('services_content_text')}
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Services;
