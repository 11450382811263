import "./Portofolio_bis.css";
import { useTranslation } from 'react-i18next'
import CDR from "../../Assets/CDR_logo.webp";
import CDR_resp from "../../Assets/responsive_CDR.webp";
const CleanDataRights = () => {
  const { t } = useTranslation()
  return (
    <article className="CDR_pres relative margin_auto">
      <section className="intro flex justify align_items_center">
        <div className="intro_container margin_auto">
          <header>
            <h1 className="block">Clean Data Rights</h1>
            <h3 className="block">{t("CDR_title")}</h3>
          </header>
          <div className="intro_desc mb-30">
            <p className="mw-400">
            {t("CDR_intro_1")}
            </p>
            <p className="mw-400">
            {t("CDR_intro_2")}
            </p>
          </div>
          <img alt="Rayzon responsiveness" src={CDR_resp} />
        </div>
      </section>
      <section className="keywords margin_auto flex justify align_items_center relative">
        <div className="keywords_container margin_auto">
          <header>
            <h2>KEYWORDS</h2>
          </header>
          <div className="identity">
            <div className="identity_work">
              <p>
              {t("CDR_keywords_text")}
              </p>
            </div>
            <div className="list_keywords">
              <ul>
                <li>{t("CDR_keywords_1")}</li>
                <li>{t("CDR_keywords_2")}</li>
                <li>{t("CDR_keywords_3")}</li>
                <li>{t("CDR_keywords_4")}</li>
                <li>{t("CDR_keywords_5")}</li>
                <li>{t("CDR_keywords_6")}</li>
              </ul>
            </div>
          </div>
          <div className="font">
            <h3 className="mb-14 fw_600">{t("CDR_font_main")}</h3>
            <h4 className="mb-30 fw_400">{t("CDR_font_second")}</h4>
            <div className="font_show">
              <div className="bookman mb-30">
                <h5>Bookman Old Style</h5>
                <p>
                  Aa Bb Cc Dd Ee Ff Gg Hh Ii Jj Kk Ll Mm Nn Oo Pp Qq Rr Ss Tt Uu
                  Vv Ww Xx Yy Zz
                </p>
              </div>
              <div className="sen mb-50">
                <h5>Sen</h5>
                <p>
                  Aa Bb Cc Dd Ee Ff Gg Hh Ii Jj Kk Ll Mm Nn Oo Pp Qq Rr Ss Tt Uu
                  Vv Ww Xx Yy Zz
                </p>
              </div>
              <div className="font_desc mb-80">
                <p>
                {t("CDR_font_text")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="keywords_background absolute"></div>
      </section>

      <section className="logo_work flex justify align_items_center">
        <div className="logo_work_container margin_auto">
          <img alt="Rayzon" src={CDR} className="block" />
          <div className="margin_auto flex">
            <p>
            {t("CDR_logo_text1")}
            </p>
            <p>
            {t("CDR_logo_text2")}
            </p>
          </div>
        </div>
      </section>
    </article>
  );
};

export default CleanDataRights;
