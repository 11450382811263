import "./Portofolio_bis.css";
import rayzon from "../../Assets/Rayzon.svg";
import rayzon_resp from "../../Assets/rayzon_responsive.webp";
import sound_icon from "../../Assets/soundicon_muted.webp";
import rayzon_vid from "../../Assets/Rayzon 1080_1920.webm";
import { useState , useRef} from "react";
import { useTranslation } from 'react-i18next'

const Rayzon = () => {
  const { t } = useTranslation()
  const [muted, setMuted] = useState(true);
  const [paused, setPaused] = useState(false);
  const vidRef = useRef(null);

    const handleClickOnVideo = () =>{
      if (paused)
        vidRef.current.play();
      else
        vidRef.current.pause();
        setPaused(current=>!current)
    }

  return (
    <article  className="rayzon_pres relative margin_auto">
      <section className="intro flex justify align_items_center">
        <div className="intro_container margin_auto">
          <header>
            <h1 className="block">Rayzon Experience</h1>
            <h3 className="block">{t("rayzon_title")}</h3>
          </header>
          <div className="intro_desc mb-30">
            <p className="mw-400">
            {t("rayzon_intro_1")}
            </p>
            <p className="mw-400">
            {t("rayzon_intro_2")}
            </p>
          </div>
          <img alt="Rayzon responsiveness" src={rayzon_resp} />
        </div>
      </section>
      <section className="keywords margin_auto flex justify align_items_center relative">
          <div className="keywords_container margin_auto">
            <header>
              <h2>KEYWORDS</h2>
            </header>
            <div className="identity">
              <div className="identity_work">
                <p>
                {t("rayzon_keywords_text")}
                </p>
              </div>
              <div className="list_keywords">
                <ul>
                  <li>{t("rayzon_keywords_1")}</li>
                  <li>{t("rayzon_keywords_2")}</li>
                  <li>{t("rayzon_keywords_3")}</li>
                  <li>{t("rayzon_keywords_4")}</li>
                  <li>{t("rayzon_keywords_5")}</li>
                  <li>{t("rayzon_keywords_6")}</li>
                  <li>{t("rayzon_keywords_7")}</li>
                  <li>{t("rayzon_keywords_8")}</li>
                  <li>{t("rayzon_keywords_9")}</li>
                </ul>
              </div>
            </div>
            <div className="font">
              <h3 className="mb-14 fw_600">{t("rayzon_font_main")}</h3>
              <h4 className="mb-30 fw_400">{t("rayzon_font_second")}</h4>
              <div className="font_show">
                <div className="montserrat mb-30">
                  <h5>Montserrat, sans serif</h5>
                  <p>
                    Aa Bb Cc Dd Ee Ff Gg Hh Ii Jj Kk Ll Mm Nn Oo Pp Qq Rr Ss Tt
                    Uu Vv Ww Xx Yy Zz
                  </p>
                </div>
                <div className="permanent_marker mb-50">
                  <h5>Permanent Marker</h5>
                  <p>
                    Aa Bb Cc Dd Ee Ff Gg Hh Ii Jj Kk Ll Mm Nn Oo Pp Qq Rr Ss Tt
                    Uu Vv Ww Xx Yy Zz
                  </p>
                </div>
                <div className="font_desc mb-80">
                  <p>
                  {t("rayzon_font_text")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="keywords_background absolute"></div>
      </section>
      <section className="logo_work flex justify align_items_center">
        <div className="logo_work_container margin_auto">
          <img alt="Rayzon" src={rayzon} className="block" />
          <div className="margin_auto flex">
            <p>
            {t("rayzon_logo_text1")}
            </p>
            <p>
            {t("rayzon_logo_text2")}
            </p>
          </div>
          <div className="relative">
            <video ref={vidRef}
            onClick={()=>handleClickOnVideo()}
              width="100%"
              height="100%"
              muted={muted}
              playsInline
              autoPlay={"autoplay"}
              preload="auto"
              loop
            >
              <source src={rayzon_vid} type="video/webm" />
            </video>
            <div
              className="videoctrl absolute justify"
              onClick={()=>handleClickOnVideo()}
              onMouseEnter={() => setMuted(false)}
              onMouseLeave={() => setMuted(true)}
            >
              <img alt="mute" src={sound_icon} className="mb-100 pb-100" />
            </div>
          </div>
        </div>
      </section>
    </article>
  );
};

export default Rayzon;
