import "./card.css"
const Card = ({img, title, desc, btn}) => {

    const handleClick = () =>{
        window.scrollTo({top :document.body.scrollHeight, left :  0 , behavior : "smooth"})
    }

    return ( 
        <div className="card">
            <div className="card_icon">
                <img src={img} alt="service_icon" preload="true"/>
            </div>
            <div className="card_title">{title}</div>
            <p className="card_desc">{desc}</p>
            <button onClick={()=>handleClick()} className="card_button">{btn}</button>
        </div>
     );
}
 
export default Card;