const PolitiqueConfid = () => {
  return (
    <div className="container mentions">
      <h1 className="section_title_fs fw_600 text_align_center">
        Politique de confidentialité
      </h1>
      <div className="section">
        <p className="lineheight mw-800 ">
          Cette Politique de confidentialité s'adresse à vous, en votre qualité
          d'utilisateur du site internet www.Ylio.net (Ci-après le «
          SITE »). Le SITE est édité par le responsable de publication de Ylio.
          <br />
          <br /> Le respect de votre vie privée et de vos données à caractère
          personnel est une priorité pour Ylio spécialisé en la
          protection des données à caractère personnel.
          <br />
          <br /> La présente Politique de confidentialité a pour objet de vous
          présenter la teneur des traitements de données à caractère personnel
          mis en œuvre sur le SITE.
          <br />
          <br /> Ylio s'engage en tout état de cause à
          respecter les trois principes essentiels suivants :
        </p>
        <ul>
          <li>
            Le Cabinet collecte uniquement les données strictement nécessaires
            au regard de leur finalité.
          </li>
          <li>Vous restez maître de vos données à caractère personnel ;</li>
          <li>
            Vos données sont traitées de manière transparente, confidentielle et
            sécurisée.
          </li>
        </ul>
        <p className="lineheight mw-800 ">
          Les traitements de données à caractère personnel sont régis :
        </p>
        <ul>
          <li>
            Par la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux
            fichiers et aux libertés (ci-après « loi IEL ») ;
          </li>
          <li>
            Par le Règlement (UE) 2016/679 du parlement européen et du conseil
            du 27 avril 2016, entré en vigueur le 25 mai 2018 (ci-après « RGPD
            »).
          </li>
        </ul>
        <div className="section_title_fs">Article 1. Définitions</div>
        <p className="lineheight mw-800 ">
          Les termes, mentionnés ci-dessous, ont dans la présente
          Politique de confidentialité, la signification suivante :
        </p>
        <ul>
          <li>
            IDENTIFIANTS : désigne les données saisies permettant de valider
            l'inscription d'un UTILISATEUR sur le SITE.
          </li>
          <li>
            RESPONSABLE DE TRAITEMENT : désigne l'entité qui détermine les
            moyens et finalités d'un traitement de données à caractère personnel.
          </li>
          <li>
            SERVICE : désigne l'ensemble des prestations proposées par la
            Société Ylio et disponibles sur le SITE ;
          </li>
          <li>
            SOUS-TRAITANT : désigne la personne traitant des données à caractère
            personnel pour le compte du RESPONSABLE DE TRAITEMENT, qui agit sous
            l'autorité du RESPONSABLE DE TRAITEMENT et sur instruction de
            celui-ci.
          </li>
          <li>
            UTILISATEUR : désigne toute personne qui accède et navigue sur le
            SITE soit en tant que simple internaute soit en tant que CLIENT.
          </li>
        </ul>
        <div className="section_title_fs">Article 2. Finalités du traitement</div>
        <p className="lineheight mw-800 ">
          Vos différentes données sont susceptibles
          d'être collectées par la société Ylio pour des finalités
          explicites, légitimes et déterminées afin d'assurer :
        </p>
        <ul>
          <li>
            Le bon fonctionnement et l'amélioration permanente du SITE, de ses
            services et de ses fonctionnalités ;
          </li>
          <li>La gestion de demandes de Rendez-vous.</li>
          <li>La gestion de demande de Devis.</li>
          <li>
            La gestion des demandes de droit d'accès, de rectification et
            d'opposition, d'effacement, de limitation, de portabilité, droit à
            la mort numérique et le droit d'introduire une réclamation auprès de
            la CNIL :
          </li>
        </ul>
        <p className="lineheight mw-800 ">
          En tout état de cause, et pour chaque finalité définie, Ylio mettra tous les moyens en sa possession pour assurer la
          sécurité et la confidentialité des données à caractère personnel qui
          leur sont confiées, dans le respect des lois et règlements en vigueur.
        </p>
        <div className="section_title_fs">Article 3. Identité du responsable de traitement</div>
        <p className="lineheight mw-800 ">
          Vos données à caractère
          personnel sont collectées et traitées par Ylio. Ylio est le responsable du traitement des données à caractère
          personnel mis en œuvre dans le cadre de l'exploitation du SITE.{" "}
        </p>
        <div className="section_title_fs">Article 4. Collecte et traitement des données personnelles</div>
        <p className="lineheight mw-800 ">
          Lorsque les
          Utilisateurs naviguent sur le SITE et/ou souhaitent bénéficier des
          services disponibles sur le SITE, Ylio peut collecter
          certaines données personnelles des Utilisateurs. Ces données sont
          traitées conformément aux finalités prévues lors de la collecte.
          <br />
          <br /> Les données susceptibles d'être collectées et traitées par
          Ylio pour la réalisation des finalités décrites à
          l'article 3 de la présente politique de confidentialité concernent :
          <br />
          Des données d'identification (nom, prénom, courriel, numéro de
          téléphone, entreprise) ;<br />
          En particulier, Ylio est susceptible de collecter des
          données à caractère personnel : <br />
          Lors de votre demande de contact via formulaire présent sur le SITE
          Quel que soit le mode de collecte, Ylio s'engage à vous
          informer des finalités du traitement, du caractère obligatoire ou
          facultatif des réponses à apporter, des conséquences éventuelles, à
          son égard, d'un défaut de réponse, des destinataires des données, de
          l'existence et des modalités d'exercice de ses droits d'accès, de
          rectification et d'opposition au traitement de ses données.{" "}
        </p>
        <div className="section_title_fs">Article 5. Consentement</div>
        <p className="lineheight mw-800 ">
          L'ensemble des données vous concernant sont collectées
          uniquement directement auprès de vous, lors de votre demande de
          contact via formulaire présent sur le SITE.
        </p>
        <div className="section_title_fs">Article 6. Destinataires des données</div>
        <p className="lineheight mw-800 ">
          Seules les personnes habilitées et
          déterminées mentionnées ci-dessous pourront avoir accès aux données
          des utilisateurs :
        </p>
        <ul>
          <li>L'expert en conformité RGPD;</li>
          <li>L'expert en développement web.</li>
        </ul>
        <p className="lineheight mw-800 ">
          Vos données ne sont communiquées, échangées, vendues ou louées à
          aucune autre personne que celles mentionnées ci-dessus.{" "}
        </p>
        <div className="section_title_fs">Article 7. Durée de conservation des données</div>
        <p className="lineheight mw-800 ">
          Ylio s'engage à ce que
          les données collectées soient conservées pendant une durée qui
          n'excède pas la durée nécessaire aux finalités pour lesquelles ces
          données sont collectées et traitées.
          <br />
          Cependant, le traitement des données est possible pour la preuve d'un
          droit ou d'un contrat. Ces données peuvent également être conservées
          dans l'objectif de respecter une obligation légale ou gardées dans des
          fichiers conformément aux règlements et lois applicables.
          <br />
          <br /> Par exception, les données d'identification de l'UTILISATEUR
          sont conservées par le Ylio pendant une durée de trois
          (3) ans à compter du dernier contact avec l'UTILISATEUR.
          <br />
          <br /> Pour la gestion de notre relation commerciale avec vous et le
          suivi clientèle vos données sont conservées 3 ans à compter de la fin
          de la relation commerciale si vous êtes client. Au-delà les données
          sont archivées pour la période où la responsabilité du cabinet peut
          être mise en cause.
          <br />
          <br />
          Pour la gestion des demandes de droit sur vos données personnelles :
          vos données sont conservées 1 an.
        </p>
        <div className="section_title_fs">Article 8. Vos droits</div>
        <p className="lineheight mw-800 ">
          Conformément à la Loi Informatique et Libertés et au RGPD,
          vous disposez des droits suivants :
        </p>
        <ul>
          <li>
            Droit d'accès (article 15 RGPD), de rectification (article 16 RGPD),
            de mise à jour, de complétude de vos données.
          </li>
          <li>
            Droit de verrouillage ou d'effacement de vos données à caractère
            personnel (article 17 RGPD), lorsqu'elles sont inexactes,
            incomplètes, équivoques, périmées, ou dont la collecte,
            l'utilisation, la communication ou la conservation est interdite.
          </li>
          <li>
            Droit de retirer à tout moment votre consentement (article 13-2c
            RGPD).
          </li>
          <li>
            Droit à la limitation du traitement de vos données (article 18 RGPD).
          </li>
          <li>
            Droit d'opposition au traitement de vos données (article 21 RGPD).
          </li>
          <li>
            Droit à la portabilité des données que vous nous avez fournies,
            lorsque vos données font l'objet de traitements automatisés fondés
            sur votre consentement ou sur un contrat (article 20 RGPD).
          </li>
          <li>
            Droit d'introduire une réclamation auprès de la CNIL (article 77
            RGPD).
          </li>
          <li>
            Droit de définir le sort de vos données après votre mort et de
            choisir que nous communiquions (ou non) vos données à un tiers que
            vous aurez préalablement désigné. En cas de décès et à défaut
            d'instructions de votre part, nous nous engageons à détruire vos
            données, sauf si leur conservation s'avère nécessaire à des fins
            probatoires ou pour répondre à une obligation légale.
          </li>
        </ul>
        <p className="lineheight mw-800 ">
          Ces droits peuvent être exercés, par simple demande par courrier
          électronique à l'adresse dpo@ylio.net en indiquant ses
          coordonnées (nom, prénom, adresse et d'une copie d'un titre d'identité
          signé) et un motif légitime lorsque celui-ci est exigé par la loi
          (notamment en cas d'opposition au traitement).
          <br />
          <br /> En cas de communication de copie de pièce d'identité pour
          justifier de votre identité, nous la conserverons un (1) an ou trois
          (3) ans lorsque cette communication est effectuée dans le cadre de
          l'exercice d'un droit d'opposition.
          <br />
          <br />
          Pour mieux connaître vos droits, vous pouvez également consulter le
          site de la Commission Nationale de l'Informatique et des Libertés,
          accessible à l'adresse suivante : http://cnil.fr.
        </p>
        <div className="section_title_fs">Article 10. Coordonnées du Délégué à la Protection des Données</div>
        <p className="lineheight mw-800 ">
           Pour toute demandes
          d'information concernant la politique de protection des données à
          caractère personnel du SITE vous pouvez vous adresser à notre Délégué
          à la Protection des Données qui est là pour répondre à toutes vos
          demandes, y compris d'exercice de droits, relatifs à vos données à
          caractère personnel. Vous pouvez le joindre à l'adresse
          dpo@ylio.net Article 10. Sécurité Ylio et ses
          éventuels sous-traitants s'engagent à mettre en œuvre toutes les
          mesures techniques et organisationnelles afin d'assurer la sécurité de
          nos traitements de données à caractère personnelle et la
          confidentialité de vos données, en application de la Loi informatique
          et Libertés et du Règlement européen sur la protection des données
          (RGPD) et de la loi n°2018-133 du 26 février 2018 « portant diverses
          dispositions d'adaptation au droit de l'Union européenne dans le
          domaine de la sécurité ».
          <br />
          <br /> A ce titre, Ylio prend les précautions utiles, au
          regard de la nature de vos données et des risques présentés par notre
          traitement, pour préserver la sécurité des données et, notamment,
          empêcher qu'elles soient déformées, endommagées, ou que des tiers non
          autorisés y aient accès (protection physique des locaux, procédé
          d'authentification de nos clients avec accès personnel et sécurisé via
          des identifiants et mots de passe confidentiels, journalisation des
          connexions, chiffrement de certaines données…). </p>        <div className="section_title_fs">Article 11. Transferts
          hors UE</div>
        <p className="lineheight mw-800 ">Ylio s'engage à ne pas transférer les données des
          UTILISATEURS en dehors de l'Union Européenne.<br/> Dans l'hypothèse où
          Ylio devrait le faire, il en informerait les UTILISATEURS
          en indiquant les mesures prises afin de contrôler ce transfert et
          s'assurer du respect de la confidentialité de leurs données.
        </p>
      </div>
    </div>
  );
};

export default PolitiqueConfid;
