
import "./copyright.css"
import { useTranslation } from 'react-i18next'
const Copyright = () => {
    const actualYear = new Date().getFullYear() 
    const { t } = useTranslation()
    return (
        <div className="copyright w-100 pb-20">Copyright © 2019 - {actualYear} Ylio {t('footpage_copyright')}</div>
    );
}
 
export default Copyright;